import hero from '../Content/images/hero.webp'

const Hero = () => {

    return <div className="hero-section">
    <div className="hero-container">
        <img src={hero} alt='' /> 
    </div>
    </div>;  

}



export default Hero;