import About from '../About.js'

const AboutPage = () => {
    return <div className="">
        <About />
    </div>;

}


export default AboutPage;