
const Date = () => {

    return <section className="date-section">
        <div className="date-container">
            <div className="date" id="date">Last Updated: 2024-01-04 </div>
        </div>
    </section>;
}



export default Date;